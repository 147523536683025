let controlBooth = {
  data() {
    return {
      userInfo: {},
      loginId: '',
      teamMemberTotal: 0,
      pageSize: 10,
      pageNumber: 1,
      teamMemberData: [],
      teamMemberColumns: [
        {
          title: '序号',
          type: 'index',
          width: '80',
          align: 'center'
        },
        {
          title: '项目名称',
          key: 'project_name',
          align: 'center',
        },
        {
          title: '项目编码',
          key: 'project_code',
          align: 'center',
        },
        {
          title: '所属公司',
          key: 'corp_name',
          align: 'center',
        },
        {
          title: '班组名称',
          key: 'team_name',
          align: 'center',
        },
        {
          title: '班组编码',
          key: 'team_code',
          align: 'center',
        },
        {
          title: '可用人数',
          key: 'valid_num',
          align: 'center',
        },
        {
          title: '已用人数',
          key: 'used_num',
          align: 'center',
        },
        {
          title: '总购买人数',
          key: 'total_num',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Icon', {
                props: {
                  type: 'person'
                }
              }),
              h('span', params.row.valid_num + params.row.used_num)
            ]);
          }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 120,
          align: 'center',
          render: (h, params) => {
            if (this.userInfo.dg_key > 2) {
              return h('div', [
                h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    background: '#03C9FF',
                    border: '1px solid #03C9FF',
                    marginRight: '15px'
                  },
                  on: {
                    click: () => {
                      this.purchase(params.row.team_id, params.row.team_name, params.row.project_id, params.row.project_name, params.row.corp_name)
                    }
                  }
                }, '购买'),
              ])
            }
          }
        }
      ],
    };
  },
  created() {
    this.userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
  },
  mounted() {
    this.getTeamMemberList();
  },
  methods: {
    // 购买跳转
    async purchase(teamId, teamName, projectId, projectName, corpName) {
      this.$router.push({
        name: "createOrder",
        params: {
          teamId: teamId,
          teamName: teamName,
          projectId: projectId,
          projectName: projectName,
          corpName: corpName
        }
      });
    },
    async getTeamMemberList() {
      // this.userInfo = await this.common.getStorage();
      // this.userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (!this.userInfo) {
        this.$router.push({ name: 'login' });
      } else {
        const { login_id } = this.userInfo
        let param = { 'login_id': login_id, 'pageNumber': this.pageNumber, 'pageSize': this.pageSize };
        let res = await this.$fetch('/rlapi/teamMember/getList',
          {
            method: "post",
            data: param
          });
        if (res) {
          if (res.code === 0) {
            this.teamMemberTotal = res.data.totalRow;
            this.teamMemberData = res.data.list;
          } else {
            // eslint-disable-next-line no-console
            console.log(res);
          }
        }
      }
    },
    changePage(index) {
      this.pageNumber = index;
      this.getTeamMemberList();
    },
    changePageSize(size) {
      this.pageSize = size;
      this.getTeamMemberList();
    }
  },
  watch: {
    dgKey(newKey) {
      this.getTeamMemberList()
    }
  },
}

export default controlBooth;