<template>
  <div class="contral-container">
    <div class="boothTitle">
      <h3>您好</h3>
      <p>欢迎进入企业管理控制台！</p>
    </div>
    <Table
      border=""
      :columns="teamMemberColumns"
      stripe
      :data="teamMemberData"
    />
    <div class="page-contianer">
      <Page
        id="teamMemberPage"
        class="teamMemberPage"
        :total="teamMemberTotal"
        show-sizer
        show-total
        show-elevator
        @on-change="changePage"
        @on-page-size-change="changePageSize"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script>
import controlBooth from "./controlBooth";

export default {
  name: 'ControlBooth',
  mixins: [controlBooth]
};
</script>

<style lang="stylus">
.contral-container
  padding-top 2.14em
  .boothTitle
    margin-bottom 2.35em
    color #434343
    h3
      font-size 2rem
    p
      1.2rem
  .boothCard
    min-height 27em
    box-shadow 0px 10px 20px 0px rgba(0,0,0,0.05)
    border-radius 10px
    font-family sans-serif
    h3
      font-size 1.4rem
    strong
      font-size 1.8rem
    .ivu-card-head
      border-bottom none
      font-size 1.285rem
      span
        font-weight bolder
    .card-content
      text-align center
      .tips-box
        margin-top 2.85em
        .tips
          line-height 2em
          font-size 1.4rem
          font-weight 300
          font-family sans-serif
  .page-contianer
    margin-top 30px
    text-align center
  .boothCard-version
    .ivu-card-head
      text-align center
    .card-content-box
      margin-bottom 3.89em
      padding 2em 1em
      font-size 1.25rem
      line-height 1.8em
    .booth-button-area
      margin 0 auto
      width 70%
  
</style>
